@font-face {
  font-family: 'EncodeSans', sans-serif;
  font-weight: 300;
  src: url('/fonts/encodeSans/EncodeSans-Light.ttf');
}

@font-face {
  font-family: 'EncodeSans', sans-serif;
  font-weight: 400;
  src: url('/fonts/encodeSans/EncodeSans-Regular.ttf');
}
